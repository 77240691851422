.react-simple-keyboard {
	background-color: var(--base-color-darker) !important;
	bottom: 0 !important;
	font-size: 1.6rem;
	left: 0 !important;
	padding: 0.5rem 3rem !important;
	position: fixed !important;
	z-index: 1000;

	.hg-button {
		background-color: var(--base-color) !important;
		border-color: var(--border-color) !important;
		color: var(--text-color) !important;

		&:focus, &:hover {
			background-color: var(--focus-color) !important;
		}
		&:active {
			background-color: var(--select-color) !important;
		}
	}

	@media all and (max-width: 480px) {
		padding: 0.5rem !important;
	}

	@media all and (max-width: 420px) {
		&, * {
			font-size: 1.3rem !important;
		}
	}

	@media all and (max-width: 380px) {
		&, * {
			font-size: 0.9rem !important;
		}
	}
}
