.slider {
	display: flex;
}

.side {
	background: #ddd;
	border: 0.1rem solid #1d1d1d;
	padding: 0.5rem;
	text-align: center;
	width: 4.5rem;

	* {
		color: #fff;
		font-weight: bold !important;
	}

	&:not(.active) {
		>* {
			display: none;
		}
	}
}

.in {
	border-right: none;

	&.active {
		background: #299c39;
	}
}

.out {
	border-left: none;
	
	&.active {
		background: red;
	}
}
