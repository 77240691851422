* {
	border: 0;
	box-sizing: border-box;
	font-family: inherit;
	font-size: 100%;
	margin: 0;
	max-width: 100%;
	outline: 0;
	padding: 0;
	vertical-align: baseline;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;

	&::selection {
		background: rgba(41,156,57,0.3);
	}
}

:root {
	--accent-color: #299c39;
}

body {
	overflow-y: scroll;
}

body, html {
	font-family: sans-serif, serif;
	font-size: 62.5%;
	font-weight: normal;
	min-width: 100%;
}

body, html, #react {
	min-height: 100vh;
}

@keyframes hops-pop {
	0% {
		transform: scale(0);
	}
	60% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes hops-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
