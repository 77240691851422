$DETAILS_PADDING: 1.5rem;

$DETAILS_PADDING_BASE: 0.75rem $DETAILS_PADDING !important;

.DetailsActions {
	justify-content: flex-start !important;
	padding: $DETAILS_PADDING !important;
}

.DetailsContent {
	padding: $DETAILS_PADDING_BASE;
}

.DetailsSummary {
	height: 6rem !important;
	min-height: 0 !important;
	padding: $DETAILS_PADDING_BASE;
}

.DetailsSummaryContent {
	align-items: center;
}
