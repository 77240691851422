$ERROR_ICON_DIMS: 7.5rem;

.error {
	font-size: 1.6rem !important;
	line-height: 2 !important;
}

.errorIcon {
	height: $ERROR_ICON_DIMS !important;
	width: $ERROR_ICON_DIMS !important;
}
