.List {
	display: grid;
	grid-template-columns: repeat(4, 1fr);

	@media all and (max-width: 640px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media all and (max-width: 480px) {
		grid-template-columns: repeat(2, 1fr);
	}
}
