.HeroButton {
	&::after {
		background: currentColor;
		bottom: 0;
		content: "";
		display: block;
		height: 3rem;
		position: absolute;
		transform: translateY(100%);
		transition: transform 0.2s ease-out;
		width: 100%;
	}
}

.HeroButtonIcon [class^="MuiButton-label"] {
	padding-top: 1.5rem;
}

.HeroButtonSuggestion {
	&::after {
		transform: none;
	}
}
