.divider {
	width: 100%;
}

.flex {
	height: 100%;
}

.message {
	margin: 0 0.75rem !important;
}

.paper {
	min-height: 13rem;
}

.slider {
	margin-top: auto;
}
